// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import slugify from 'slugify'
import { Flipper, Flipped } from 'react-flip-toolkit'
import Slick from 'react-slick'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import Scroll from 'react-scroll'
import Obfuscate from 'react-obfuscate'
// import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import Analytics from '../utils/analytics'
import RenderIf from '../components/RenderIf'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import Icon from '../components/Icon'
import PaddedContent from '../components/PaddedContent'
import ContactFormWithEmailLink from '../components/ContactFormWithEmailLink'
import Button from '../components/Button'
import { type FluidWithWebp } from '../types'
import { Header, TeamMembers, News } from '../styles/pages/about-us'

type Props = {
  location: any,
}

type State = {
  expandedItemId: string | null,
}

type StaticQueryData = {
  contentfulAboutUsPage: {|
    pageTitle: string,
    keywords: Array<string>,
    description: {
      description: string,
    },
    headingBackgroundImage: {
      title: string,
      ...FluidWithWebp,
    },
    headingTitle: string,
    headingSubtitle: string,
    teamMembersTitle: string,
    teamMembers: Array<{|
      id: string,
      name: string,
      title: string,
      email: string,
      linkedInURL?: string,
      biography: { biography: string },
      photo: {
        title: string,
        ...FluidWithWebp,
      },
    |}>,
    newsTitle: string,
    newsItems: Array<{|
      id: string,
      title: string,
      pullQuote: { pullQuote: string },
      articleText?: {
        childContentfulRichText: {
          html: string,
        },
      },
      date: string,
      thumbnail: {
        title: string,
        ...FluidWithWebp,
      },
      images: Array<{|
        id: string,
        title: string,
        file: {
          url: string,
          details: {
            image: {
              width: string,
            },
          },
        },
      |}>,
      videoURLs: Array<string>,
    |}>,
    contactFormTitle: string,
    contactFormSubtitle: string,
    contactFormButtonLabel: string,
  |},
}

class AboutUsPage extends React.Component<Props, State> {
  state = {}

  componentDidMount() {
    Analytics.pageview()
  }

  handleNewsItemClick = (id: string) => {
    this.setState({
      expandedItemId: id,
    })

    setTimeout(() => {
      if (this) {
        Scroll.scroller.scrollTo(id, {
          duration: 500,
          smooth: true,
          offset: -24,
        })
      }
    }, 500)
  }

  handleNewsItemContractClick = () => {
    this.setState({ expandedItemId: null })
  }

  render() {
    const { location } = this.props

    return (
      <StaticQuery
        query={indexPageQuery}
        render={(data: StaticQueryData) => {
          const {
            pageTitle,
            keywords,
            description: { description },
            headingBackgroundImage,
            headingTitle,
            headingSubtitle,
            teamMembersTitle,
            teamMembers,
            newsTitle,
            newsItems,
            contactFormTitle,
            contactFormSubtitle,
            contactFormButtonLabel,
          } = data.contentfulAboutUsPage

          const slugs = {
            teamMembers: slugify(teamMembersTitle, { lower: true }),
            news: slugify(newsTitle, { lower: true }),
            // media: slugify(mediaTitle, { lower: true })
          }
          const { expandedItemId = newsItems[0].id } = this.state
          return (
            <Layout dependencies={['slick']}>
              <SEO
                title={pageTitle}
                description={description}
                keywords={keywords}
                location={location}
              />
              <Header>
                <Header.Background>
                  <Img fluid={headingBackgroundImage.fluid} alt={headingBackgroundImage.title} />
                </Header.Background>
                <Nav location={location} />
                <PaddedContent>
                  <Header.Title>{headingTitle}</Header.Title>
                  <Header.Subtitle>{headingSubtitle}</Header.Subtitle>
                  <Header.Buttons>
                    <Button
                      to={`/about-us#${slugs.teamMembers}`}
                      label={teamMembersTitle}
                      kind="outline-on-dark"
                      analytics={`About Us: ${teamMembersTitle}`}
                    />
                    <Button
                      to={`/about-us#${slugs.news}`}
                      label={newsTitle}
                      kind="outline-on-dark"
                      analytics={`About Us: ${newsTitle}`}
                    />
                    {/* <Button to="/about-us#media" label="Media" type="outline-on-dark" /> */}
                  </Header.Buttons>
                </PaddedContent>
              </Header>
              <PaddedContent>
                <TeamMembers>
                  <TeamMembers.Title id={slugs.teamMembers}>{teamMembersTitle}</TeamMembers.Title>
                  <TeamMembers.Grid>
                    {teamMembers.map(teamMember => (
                      <TeamMembers.TeamMember key={teamMember.id}>
                        <TeamMembers.TeamMember.Photo>
                          <Img fluid={teamMember.photo.fluid} title={teamMember.photo.title} />
                        </TeamMembers.TeamMember.Photo>
                        <TeamMembers.TeamMember.Name>{teamMember.name}</TeamMembers.TeamMember.Name>
                        <TeamMembers.TeamMember.Title>
                          {teamMember.title}
                        </TeamMembers.TeamMember.Title>
                        <TeamMembers.TeamMember.Email>
                          <Obfuscate
                            email={teamMember.email}
                            onClick={() => {
                              Analytics.event({
                                category: 'Outbound Navigation',
                                action: 'Click',
                                label: `Team Member: ${teamMember.name}: Email Address`,
                              })
                            }}
                          />
                        </TeamMembers.TeamMember.Email>
                        {teamMember.linkedInURL && (
                          <TeamMembers.TeamMember.Social
                            href={teamMember.linkedInURL}
                            target="_blank"
                            rel="noopener"
                            analytics={`Team Member: ${teamMember.name}: LinkedIn`}
                          >
                            <span>LinkedIn profile for {teamMember.name}</span>
                            <Icon type="linked-in" />
                          </TeamMembers.TeamMember.Social>
                        )}
                        <TeamMembers.TeamMember.Biography>
                          {teamMember.biography.biography}
                        </TeamMembers.TeamMember.Biography>
                      </TeamMembers.TeamMember>
                    ))}
                  </TeamMembers.Grid>
                </TeamMembers>
              </PaddedContent>
              <News>
                <div id="goji-in-the-news">
                  <PaddedContent>
                    <News.Title id={slugs.news}>{newsTitle}</News.Title>

                    <Flipper flipKey={expandedItemId}>
                      <News.Items>
                        {newsItems.map(item => {
                          const expanded = expandedItemId === item.id
                          return (
                            <Flipped flipId={item.id} key={item.id}>
                              <News.Item
                                key={item.id}
                                id={item.id}
                                expanded={expanded}
                                onClick={() => {
                                  if (!expanded) {
                                    Analytics.event({
                                      category: 'Navigation',
                                      action: 'Click',
                                      label: `News Items: ${item.title}`,
                                    })

                                    this.handleNewsItemClick(item.id)
                                  }
                                }}
                              >
                                <Scroll.Element name={item.id} />
                                <News.Item.Photo expanded={expanded}>
                                  <Img fluid={item.thumbnail.fluid} alt={item.thumbnail.title} />
                                  <RenderIf condition={!expanded}>
                                    <News.Item.Icon>
                                      <Icon type="expand" />
                                    </News.Item.Icon>
                                  </RenderIf>
                                  <RenderIf condition={expanded}>
                                    <News.Item.Icon onClick={this.handleNewsItemContractClick}>
                                      <Icon type="contract" />
                                    </News.Item.Icon>
                                  </RenderIf>
                                </News.Item.Photo>
                                <RenderIf condition={!expanded}>
                                  <News.Item.MetaCollapsed>
                                    <News.Item.Title>{item.title}</News.Item.Title>
                                    <News.Item.Date>{item.date}</News.Item.Date>
                                    <News.Item.PullQuote>
                                      <News.Item.PullQuote.Icon>
                                        <Icon type="quote" />
                                      </News.Item.PullQuote.Icon>
                                      <News.Item.PullQuote.Quote>
                                        {item.pullQuote.pullQuote}
                                      </News.Item.PullQuote.Quote>
                                    </News.Item.PullQuote>
                                  </News.Item.MetaCollapsed>
                                </RenderIf>
                                <RenderIf condition={expanded}>
                                  <News.Item.MetaExpanded>
                                    <News.Item.Title>{item.title}</News.Item.Title>
                                    <News.Item.Date>{item.date}</News.Item.Date>
                                    <News.Item.PullQuote>
                                      <News.Item.PullQuote.Icon>
                                        <Icon type="quote" />
                                      </News.Item.PullQuote.Icon>
                                      <News.Item.PullQuote.Quote>
                                        {item.pullQuote.pullQuote}
                                      </News.Item.PullQuote.Quote>
                                    </News.Item.PullQuote>
                                    {item.articleText && (
                                      <News.Item.Text
                                        dangerouslySetInnerHTML={{
                                          __html: `<div>${item.articleText.childContentfulRichText.html}</div>`,
                                        }}
                                      />
                                    )}
                                  </News.Item.MetaExpanded>
                                  <News.Item.Images>
                                    <Slick infinite={false}>
                                      {item.images &&
                                        item.images.map(image => (
                                          <News.Item.Image key={image.id}>
                                            <img
                                              src={image.file.url}
                                              alt=""
                                              style={{
                                                maxWidth: `${image.file.details.image.width}px`,
                                              }}
                                            />
                                          </News.Item.Image>
                                        ))}
                                      {item.videoURLs &&
                                        item.videoURLs.map(url => (
                                          <YouTubePlayer
                                            key={url}
                                            url={url}
                                            light
                                            controls
                                            // playing
                                            width="100%"
                                            // height="100%"
                                            config={{
                                              youtube: { playerVars: { modestbranding: 0 } },
                                            }}
                                            // onPlay={() => {
                                            //   ReactGA.event({
                                            //     category: 'Video',
                                            //     action: 'Started or Resumed',
                                            //   })
                                            // }}
                                            // onPause={() => {
                                            //   ReactGA.event({
                                            //     category: 'Video',
                                            //     action: 'Paused',
                                            //     value: Math.round(this.player.getCurrentTime()),
                                            //   })
                                            // }}
                                            // onEnded={() => {
                                            //   ReactGA.event({
                                            //     category: 'Video',
                                            //     action: 'Finished',
                                            //   })
                                            // }}
                                          />
                                        ))}
                                    </Slick>
                                  </News.Item.Images>
                                </RenderIf>
                              </News.Item>
                            </Flipped>
                          )
                        })}
                      </News.Items>
                    </Flipper>
                  </PaddedContent>
                </div>
              </News>
              <ContactFormWithEmailLink
                title={contactFormTitle}
                subtitle={contactFormSubtitle}
                buttonLabel={contactFormButtonLabel}
              />
            </Layout>
          )
        }}
      />
    )
  }
}

export default AboutUsPage

const indexPageQuery = graphql`
  query AboutUsPageQuery {
    contentfulAboutUsPage {
      pageTitle
      keywords
      description {
        description
      }
      headingBackgroundImage {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      headingTitle
      headingSubtitle
      teamMembersTitle
      teamMembers {
        id
        name
        title
        email
        linkedInURL
        biography {
          biography
        }
        photo {
          title
          fluid(maxWidth: 360, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      newsTitle
      newsItems {
        id
        title
        pullQuote {
          pullQuote
        }
        date
        articleText {
          childContentfulRichText {
            html
          }
        }
        thumbnail {
          title
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        images {
          id
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        videoURLs
      }
      contactFormTitle
      contactFormSubtitle
      contactFormButtonLabel
    }
  }
`
